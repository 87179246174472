export default {
    dashboard: {
        "": "дашборд",
        cant_fit: "Цей віджет не поміщається",
        service: {
            welcome: "Зустрічайте — дашборд",
            promo_configure: "Налаштуйте ваш персональний простір та контролюйте показники одним поглядом",
            promo_position: "Розміщуйте різноманітні віджети так, як вам це зручно",
            promo_menu: "Оберіть шаблон \"Меню\" у налаштуваннях, щоб повернути класичний вигляд",
            got_it: "Зрозуміло",

        },
        settings: {
            "": "настройки дашборду",
            template: {
                choose: "обрати шаблон",
            },
            data: {
                "": "джерела даних",
            },
            reset: {
                "": "скинути налаштування",
                sure: "Ви впевнені, що бажаєте скинути розмітку дашборда та пов'язані з ним налаштування?",
                short: "скинути",
            },
            actions: {
                delete: "видалити",
                cut: "вирізати",
                paste: "вставити",
                add: "додати",
                add_row: "додати рядок",
                add_column: "додати стовбчик",
            },
        },
        setup: {
            find_widgets: "Пошук віджетів...",
        },
        nav: {
            "": "Посилання",
            choose_link: "Оберіть призначення",
        },
        hints: {
            "": "Підказки",
            will_be_soon: "Незабаром тут з'являться підказки",
        },
        clients: {
            "": "Клієнти monobank",
            sign: "Клієнтів monobank",
            choose_type: "Оберіть тип",
        },
    },
    statement: {
        "": "виписка",
        load_more: "загрузити ще",
        no_operations_for_last_week: "ви не користувались цим рахунком протягом останніх семи днів",
        still_nothing: "все ще порожньо",
        warning: "попередження",
        requests_throttling: `Цей акаунт прив'язано використовуючи персональний токен. 
        Через те, що monobank обмежує частоту надання доступу до виписки, дані завантажуватимуться повільно. 
        Рекомендовано підключення рахунку з використанням спрощеної авторизації.`,
        hint_customize: "Не те, що очікували?",
        hint_customize_text: "Керуйте порядком карток та їх зовнішнім виглядом у меню \"Мої картки\"",
        open: "відкрити",
        choose_platinum_color: "оберіть колір вашої платинової карти",
        accounts_changed: "помилка списку рахунків",
        accounts_changed_text: "схоже, список рахунків було оновлено. Перезавантажте для застосування змін",
        operation: "операція",
    },
    customization: {
        open: "мої картки",
        card: "картка",
        bank: "банк",
        look: "тип картки",
        cardholder: "носій",
        currency: "валюта",
        looks: {
            black: "чорна",
            grey: "сіра",
            pink: "рожева",
            white: "біла",
            iron: "залізна",
            yellow: "жовта",
        },
        warning: "Через обмеження monobank API застосунок не може визначити тип вашої картки. Тим не менш, ви можете задати її власноруч",
    },
    currencies: {
        retry: "ще раз",
        fetch_fail: "помилка отримання курсів",
        try_later: "спробуйте пізніше",
    },
    menu: {
        "": "меню",
        app_upgraded: "застосунок оновлено",
        see_whats_new: "натисніть, щоб дізнатись, що нового",
    },
    auth: {
        "": "Авторизація",
        you_were_unlogined: "вас було деавторизовано",
        reload: "перегрузити",
        login_params_changed: "параметри входу було змінено. Оберіть \"Налаштування\", якщо бажаєте повернути їх до початкового стану",
        login_promo: "увійдіть, щоб використовувати всі можливості Mono PWA",
        log_in: "увійти",
        skip_step: "минути крок",
        unknown_auth_method: "невідомий метод входу",
        success: "успіх",
        fail: "Не вдалось підтвердити достовірність даних",
        login_data_fetch_fail: "Не вдалось отримати дані для входу",
        settings: {
            "": "налаштування",
            token: {
                "": "Використовувати власний токен",
                description: "Застосунок надсилатиме запити до monobank напряму. Ви можете отримати токен на порталі api.monobank.ua",
                title: "Власний токен",

                token: "токен",
            },
            domain: {
                "": "Змінити корпоративний сервер",
                description: "Якщо ви не довіряєте серверу, на який надсилаються запити при спрощеній авторизації, ви можете змінити його",
                title: "Змінити сервер",

                unsupported_server: "несумісний сервер",
                url: "адреса",

                proto_detected: "виявлено сервер",
                name: "назва",
                author: "автор",
                proto_ver: "версія протокола",
                visit_homepage: "домашня сторінка",
                server_message: "повідомлення від",
                see_link: "відкрити посилання",
            },
            revert: {
                "": "Продовжити як зазвичай",
                description: "Відмовитись від розширеного налаштування авторизації та повернутись до вмовчань",
            },
        },
        stage: {
            link: "посилання",
            opening: "відкриваємо monobank...",
            copied: "Посилання скопійовано до буферу обміну, надалі керуйтесь нижче зазначеними кроками",
            auto_link: "Вхід має відбутись автоматично. Якщо ж цього не сталося, керуйтесь нижче зазначеними кроками",
            open: "відкрити",
            qr: "QR",
            cancel: "скасувати",
            authed: "авторизовано",
            error: "виникла помилка",
            timeout: "час вичерпано",
            instructions: {
                scan: {
                    "": "Зіскануйте QR код",
                    description: "Відкрийте monobank на вашому телефоні. Оберіть вкладку \"Ще\". Натисніть \"Сканер QR-коду\"",
                },
                follow: {
                    "": "Перейдіть за посиланням",
                    description: "Відкрийте посилання, або ж скопіюйте URL адресу у браузер на вашому мобільному пристрої",
                },
                permissions: {
                    "": "Надайте доступ",
                    description: "Підтвердіть у вікні доступ до деяких даних",
                },
            },
        },
    },
    p4: {
        "": "покупка частинами",
        partners: "партнери",
        fetch_fail: "помилка отримання списку партнерів",
        try_later: "спробуйте пізніше",
        retry: "повторити",
        cached_title: "збережена копія",
        cache_for: "дані станом на",
        online: "замовлення онлайн",
        delivery: "з доставкою",
        delivery_exp: "цей індикатор означає, що при покупці частинами партнер надає можливість оформити замовлення онлайн з доставкою",
        online_exp: "цей індикатор означає, що при покупці частинами партнер надає можливість оформити замовлення онлайн, але потрібно відвідувати магазин",
        categories: "категорії",
        category: "категорія",
        choose_category: "оберіть категорію",
        all: "усі",
        no_data: "нема даних для показу",
    },
    currency: {
        "": "курси валют",
        one: "валюта",
        numbers: "курс",
        buy: "купівля",
        sell: "продаж",
        refresh: "оновити",
        payment_systems: "курси платіжних систем",
        result: "результат",
        amount: "кількість",
        convert: "конвертер валют",
        refreshing: "оновлення...",
        refreshed: "оновлено",
        error_refresh: "помилка оновлення",
        cached_title: "збережена копія",
        cache_for: "курс станом на",
        fetch_fail: "помилка одержання курсу валют",
        try_later: "спробуйте пізніше",
        rerty: "повторити",
    },
    recovery_mode: {
        "": "Аварійний режим",
        enter: "перезавантажте застосунок для входу у Аварійний режим",
        now: "Аварійний режим активний",
        back_to_normal: "Перезавантажте застосунок без спеціального хешу, щоб покинути його",
        idb_fail: {
            warning: "увага",
            description: "Виникли труднощі при спробі використання сховища браузера. Зверніть увагу, що застосунок не може працювати у приватному режимі Firefox",
        },
    },
    units: {
        min: {
            type: "func",
            name: "plural",
            data: ["хвилина", "хвилини", "хвилин"],
        },
    },
    dateformats: {
        week: {
            "": "тиждень",
            days: {
                "": "дні тижня",
                7: "неділя",
                1: "понеділок",
                2: "вівторок",
                3: "середа",
                4: "четвер",
                5: "п'ятниця",
                6: "субота",
            },
        },
        relative: {
            today: "сьогодні",
            yesterday: "вчора",
            tomorrow: "завтра",
        },

        month: {
            "": "місяць",
            1: "січень",
            2: "лютий",
            3: "березень",
            4: "квітень",
            5: "травень",
            6: "червень",
            7: "липень",
            8: "серпень",
            9: "вересень",
            10: "жовтень",
            11: "листопад",
            12: "грудень",
            months: {
                "": "місяця",
                1: "січня",
                2: "лютого",
                3: "березня",
                4: "квітня",
                5: "травня",
                6: "червня",
                7: "липня",
                8: "серпня",
                9: "вересня",
                10: "жовтня",
                11: "листопада",
                12: "грудня",
            },
        },
        at: "о",
    },
    settings: {
        "": "налаштування",
        tile_name: "настройки",
        locked_item: "Підказка: Ви не можете вносити тут зміни",
        restart_to_apply: "перезавантажте для застосування змін",
        errors: {
            no_page: "такої сторінки налаштувань нема",
            layout_failed: "схоже, у нас не вийшло завантажити Налаштування",
        },
        descriptions: {
            about_app: "Версія, додаткова інформація",
            storage: "Використовуваний простір, звіти, кеш",
            language: "Українська, російська",
            notifications: "Новини, зміна балансу",
            offline_mode: "Використовувати збережені дані",
            show_minor_part: "Відображати суми у форматі 00.00",
            hide_credit_limit: "Віднімати кредитний ліміт від балансу",
            experiments: "Функціональність, що у процесі тестування",
            dashboard: "Віджети та джерела інформації",
        },
        auth: {
            "": "авторизація",
            log_in: "увійти",
            not_logined_title: "ви не увійшли в акаунт",
            not_logined_text: "увійдіть, щоб використовувати всі функції",
            personal_token: "персональний токен",
            monobank_account: "акаунт monobank",
            add_account: "додати акаунт",
        },
        notifications: {
            "": "сповіщення",
            info: "У цьому списку відображаються доступні джерела сповіщень на підставі ваших налаштувань",
            list: "наявні джерела",
            source: "джерело сповіщень",
            empty: "немає пропозицій",
            empty_info: "авторизуйтеся у підтримуваний акаунт monobank, щоб підписуватись на сповіщення",
            push_service_url: "Сервіс сповіщень",
            push_service: "API",
            no_push_services_hint_title: "Не розумієте, чому тут порожньо?",
            no_push_services_hint_body: "Дізнайтесь, як працюють сповіщення",
            no_push_services_hint_link: "https://sominemo.com/mono/help/article/uk/how-push-servers-work",
        },
        privacy: {
            "": "приватність",
            info: "Надсилання звітів про помилки та аналітики",
            analytics: "аналітика",
            reports: "звіти",
            send_errors: {
                "": "надавати дані про помилки",
                info: "Надсилання мінімальної інформації для виправлення. Містить у собі текст помилки, місце, де вона трапилась, версію застосунку, назву і версію браузера/ОС",
            },
            send_reports: {
                "": "автоматично надсилати звіти",
                info: "Надсилання журналу подій застосунка. Містить у собі події та помилки, статистику використання, версію застосунку, назву і версію браузера та ОС",
            },
            debug_log: {
                "": "вести докладний звіт",
                info: "Збір усієї можливої інформації для зневадження. Не вмикайте, якщо вам це не потрібно",
            },
        },
        actions: {
            open_about: "про програму",
            go_main: "на головну",
            restart: "перезавантажити",
        },
        general: {
            "": "загальні",
            information: "інформація",
        },
        language: "мова",
        storage: {
            "": "сховище",
            used: "використано",
            of: "з",
            calculating: "розрахунок",
            cleanup_planned: "наступного запуску буде виконано очищення",
            over_quota: "сховище перевищило ліміт, але не підтримує автоматичне очищення",
            dbs: {
                logs: {
                    "": "звіти",
                    description: "основна інформація про роботу програми для налагодження",
                },
                offline_cache: {
                    "": "автономні дані",
                    description: "збережена інформація, отримана з Інтернету, для роботи з нею без підключення до мережі",
                },
                accounts: {
                    "": "акаунти",
                    description: "ваші авторизовані профілі monobank",
                },
                statement_cache: {
                    "": "виписка",
                    description: "збережена копія виписок з ваших акаунтів",
                },
                card_settings: {
                    "": "картки",
                    description: "налаштування карток, такі як їх порядок, колір і т.п.",
                },
            },
            actions: {
                clear: "очистити",
                export: "експорт",
                import: "імпорт",
                log_out: {
                    "": "вийти",
                    you_will_log_out: "продовжуючи, ви вийдете з усіх акаунтів",
                },
            },
        },
        updates: {
            "": "оновлення",
            ready: "оновлення вже тут",
            tell_more: "більше",
            later: "пізніше",
            restart_now: "застосувати зараз",
            new_update: "нова версія готова до встановлення",
        },

        tf: {
            "": "трансформатори",
            tf_instances: "екземпляри",
            unlocked: "розблоковано",
            locked: "заблоковано",
            tf_methods: "методи",
        },
    },
    about: {
        "": "про",
        app: "про програму",
        version: "версія",
        build: "збірка",
        build_date: "дата збирання",
        branch: "гілка",
        debug: "зневаджування",
        build_flags: "прапори збирання",
        disclaimer_title: "дисклеймер",
        disclaimer: `Mono PWA не є офіційним додатком і не стосується monobank будь-яким чином.
Додаток використовує загальнодоступне API і не передає дані про використання на зберігання будь-кому.
Якщо ви проти передачі своїх особистих даних для підтримки роботи сервісу, ви повинні відмовитися від сервісу спрощеної авторизації.
Ви завжди в праві використовувати авторизацію за допомогою персонального токена, щоб дані передавалися
безпосередньо в monobank. Ви берете на себе відповідальність за будь-які вихідні запити від імені цієї програми, зроблені
вами свідомо.
Застосунок збирає деякі деперсоніфіковані дані, такі як кількість прив'язаних акаунтів, наявність придбаної валюти, 
найчастіша категорія транзакції, тощо, через сервіс Google Analytics. Ваші ім'я, прізвище, унікальні ідентифікатори 
та інші чутливі дані не підлягають передачі будь-кому. Усі дані, що передаються, використовуються розробником виключно 
з метою аналізу аудиторії, покращуючи у такий спосіб досвід використання програми. Ви завжди можете відмовитись від збирання 
даних у меню "Приватність" або включивши функцію "Do Not Track" у налаштуваннях браузера. 
Ви завжди в праві звернутися за сирцевим кодом програми за адресою me@sominemo.com,
змінювати, публікувати, виконувати код в будь-якій формі, при цьому відповідальність за вміст вже буде саме на вас.`,
    },
    experiments: {
        "": "експерименти",
        miscellaneous: "різне",
        warning: "попередження",
        harmful_actions: `ці опції експериментальні і деякі з них можуть викликати проблеми, порушувати стабільність програми або зовсім перешкоджати її роботі.
        Після застосування змін варто перезавантажити застосунок.`,
        reload_page: "перезавантажити",
        reset_flags: "скинути",
        list: "список",
        no_exps_placeholder: "зараз тестувати нічого",
        about: {
            deny_analytics: {
                title: "Відмовитись від збору даних",
                about: `Відключити сервіс Google Analytics і припинити збір деперсоніфікованих даних, які використовуються для покращення досвіду використання (докладніше в меню "Про програму" › "Дисклеймер").
                Цей прапор включається автоматично, якщо в браузері включені Do Not Track запити`,
            },
            miscellaneous_in_settings: {
                title: "Показати меню «Експерименти» в меню",
                about: "Додає цю сторінку в меню налаштувань",
            },
            next_features: {
                title: "Включити функції з майбутнього релізу",
                about: "Даний прапор не регламентує свого впливу та може змінювати його у будь-який момент",
            },
            enable_tab_navigation: {
                title: "Навігація за допомогою клавіші TAB",
                about: `Симуляція навігації у застосунку, де натискання на TAB рівноцінне
                переходу до наступного елементу, а натискання Enter - кліку по елементу`,
            },
        },
    },
    download_statement: {
        "": "завантажити виписку",
        download: "почати",
        working: "завантаження",
        disable_offline_mode: "вимкніть автономний режим",
        from: "від",
        to: "до",
        incorrect_range: "неправильний проміжок",
        description: "Завантаження виписки може знадобитись, якщо ви хочете користуватись історією операцій за певний період без доступу до мережі. Буде збережено виписку кожного доступного рахунку.",
    },
    push: {
        i: {
            std: {
                sign: "Сповіщення",
                description: "Джерело сповіщень",
            },
            statement: {
                unknown_account: "Рахунок",
                description: "Сповіщення про транзакції",
            },
            news: {
                sign: "Новини",
                description: "Список змін, важлива інформація",
            },
        },
        not_supported: "Не підтримуються на вашому пристрої. Натисніть, щоб дізнатись більше",
        not_supported_title: "Функція недоступна",
        not_supported_text: "Сповіщення не підтримуються в браузері Safari на MacOS, а також у будь-якому браузері на iOS/iPadOS та движку WebKit у цілому. Це пов'язано з тим, що Apple не дотримується Web-стандартів та обмежує розробників. Для того, щоб мати можливість надсилати сповіщення користувачам Safari, Apple вимагає наявність аккаунта розробника, що означає наявність MacBook і регулярну плату $100/рік. Mono PWA є безкоштовною програмою, що розробляється на безоплатній основі, розробник якого такими ресурсами не володіє. \n\nРозгляньте варіант використання іншого браузера, якщо ви на MacOS. \nРозгляньте варіант використання іншого пристрою, якщо ви на iOS. \n\nСлава Apple.",
    },
    fatal_error: {
        "": "Сталась помилка",
        explainer: "Застосунок не може завантажитись",
        actions: {
            "": "Що ви можете зробити:",
            send: {
                title: "Надіслати звіт",
                info: "Для аналізу та виправлення",
                title_sent: "Звіт надіслано",
                info_sent: "Дякуємо за допомогу",
                info_auto: "Включене автонадсилання",
            },
            more: {
                title: "Інші варіанти",
                info: "Звітування, кеш, скидання налаштувань",
            },
            replay: {
                title: "Відтворити помилку",
                info: "З записом детального звіту",
                info_enabled: "Детальний звіт вже ведеться",
            },
            clear: {
                title: "Очистити кеш",
                info: "Застосунок не працюватиме без мережі",
            },
            reset: {
                title: "Виконати скидання",
                info: "Акаунти та налаштування буде втрачено",
            },
            help: {
                title: "Звернутись по допомогу",
                info: "Зворотній зв'язок у чаті Telegram",
            },
            export: {
                title: "Експортувати звіт",
                info: "Буде згенеровано файл",
            },
        },
    },
    hints: {
        list: {
            pushes: {
                title: "Слідкуйте за операціями",
                info: "Більше про меню \"Сповіщення\"",
            },
            updates: {
                title: "Слідкуйте за новинами",
                info: "Отримуйте сповіщення про оновлення у mono PWA",
            },
            change_order: {
                title: "Змінюйте порядок карток",
                info: "Дізнайтесь про меню \"Мої картки\"",
            },
            offline_statement: {
                title: "Виписка без мережі",
                info: "Завантажуйте історію наперед",
            },
            convert_currencies: {
                title: "Конвертуйте валюти",
                info: "Завдяки функції у меню \"Курси валют\"",
            },
            find_currencies: {
                title: "Шукайте валюти",
                info: "Дізнайтесь про приховані можливості меню \"Курси валют\"",
            },
            partner_sections: {
                title: "Категорії у списку партнерів",
                info: "Шукайте бажаного продавця швидше",
            },
        },
    },
    greeting: {
        morning: "доброго ранку!",
        afternoon: "добрий день!",
        evening: "добрий вечір!",
        night: "доброї ночі!",
        generic: "вітаємо!",
    },
    unexpected_error: "ой!",
    select_option: "оберіть зі списку",
    tap_to_change: "натисніть, щоб змінити",
    done: "готово",
    select_file: "оберіть файл",
    success: "успіх",
    failure: "невдача",
    dev_warn: "ця функція знаходиться у стані розробки",
    preview_warn: "ця функція зараз тестується та може працювати неправильно",
    close: "закрити",
    next: "далі",
    accept: "приняти",
    continue: "далі",
    search: "пошук",
    offline_mode: "автономний режим",
    hide_credit_limit: "приховати кредитні кошти",
    quick_settings: "опції",
    show_minor_part: "показувати копійки",
    recommendation: "рекомендація",
    tip: "порада",
    hint: "підказка",
    message: "повідомлення",
    reload: "перезавантажити",
    enable_accessibility: "включити спеціальні можливості",
    skip_nav: "минути меню",
    help: "допомога",
}
