// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../icons/outline/font.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,500&subset=cyrillic);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Product+Sans:400&subset=cyrillic&display=block);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400&display=block);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Material Icons';\n  font-style: normal;\n  font-weight: 400;\n  font-display: block;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2');\n}\n\n:root {\n  --font-main: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', Arial, sans-serif;\n  --font-accent: 'Product Sans', var(--font-main);\n  --font-mono: 'Roboto Mono', Consolas, monospace;\n}", "",{"version":3,"sources":["fonts.css"],"names":[],"mappings":"AAIA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,4DAAqD;AACvD;;AAEA;EACE,+HAA+H;EAC/H,+CAA+C;EAC/C,+CAA+C;AACjD","file":"fonts.css","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&subset=cyrillic');\n@import url('https://fonts.googleapis.com/css?family=Product+Sans:400&subset=cyrillic&display=block');\n@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400&display=block');\n\n@font-face {\n  font-family: 'Material Icons';\n  font-style: normal;\n  font-weight: 400;\n  font-display: block;\n  src: url(../icons/outline/font.woff2) format('woff2');\n}\n\n:root {\n  --font-main: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', Arial, sans-serif;\n  --font-accent: 'Product Sans', var(--font-main);\n  --font-mono: 'Roboto Mono', Consolas, monospace;\n}"]}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
